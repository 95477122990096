
  import each from 'lodash/each'
  import SearchResult from '@/components/search/SearchResult'
  import EventBus, { BusEvents } from '@/services/eventBus/EventBus'
  import SearchResultContainerSection from '@/components/search/SearchResultContainerSection'

  const ListLoadIndicator = () => import('@/components/general/elements/ListLoadIndicator')
  const Button = () => import('@/components/general/elements/Button')

  const TopMargin = 90
  const SectionHeight = 67
  const LinkHeight = 22
  const ButtonHeight = 40

  export default {
    name: 'SearchResultContainer',
    components: { ListLoadIndicator, SearchResultContainerSection, Button },
    props: {
      searchResult: {
        type: SearchResult,
        required: true
      },
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        sections: []
      }
    },
    watch: {
      searchResult: {
        deep: true,
        immediate: true,
        handler ({ throttledSections }) {
          this.processSections(throttledSections)
        }
      }
    },
    mounted () {
      EventBus.$on(BusEvents.app.windowResize, this.windowSizeChanged)
    },
    beforeDestroy () {
      EventBus.$off(BusEvents.app.windowResize, this.windowSizeChanged)
    },
    methods: {
      windowSizeChanged (size) {
        this.processSections(this.searchResult.throttledSections)
      },
      processSections (sections) {
        const copySections = { ...sections }
        let prioritized = ['nonsingle_products', 'singles_lorcana', 'singles_mtg', 'buylist_offers']
        const aroundHeight = TopMargin + ButtonHeight
        const spaceAvailable = (process.client ? window.innerHeight : 0) - aroundHeight

        if (spaceAvailable > SectionHeight) {
          const sectionsCount = Math.floor(spaceAvailable / (SectionHeight + 3 * LinkHeight))
          const sectionKeys = Object.keys(copySections)

          if (sectionsCount < 2) {
            prioritized = [prioritized[0]]
          } else {
            for (let i = 0; i < sectionsCount - 2; i++) {
              each(sectionKeys, key => {
                if (!prioritized.includes(key)) {
                  prioritized.push(key)
                  return false
                }
              })
            }
          }

          for (let i = 0; i < sectionKeys.length; i++) {
            if (!prioritized.includes(sectionKeys[i])) {
              delete copySections[sectionKeys[i]]
            }
          }
          this.sections = copySections
        } else {
          this.sections = []
        }
      }
    }
  }
