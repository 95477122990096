
  import uniqBy from 'lodash/uniqBy'
  import SearchResultSection from './SearchResultSection'
  import NLink from '~/components/utils/NLink'
  import SearchResult from '@/components/search/SearchResult'

  export default {
    name: 'SearchResultContainerSection',
    components: { NLink },
    props: {
      section: {
        type: SearchResultSection,
        required: true
      }
    },
    data () {
      return {
        countLimits: SearchResult.piecePerSection
      }
    },
    computed: {
      uniqueSectionItems (vm) {
        return uniqBy(vm.section.items, 'title')
      }
    },
    methods: {
      linkClicked () {
        this.$emit('linkClicked')
      }
    }
  }
